/* eslint-disable react/prop-types */

import { useEffect, useRef, useState } from "react";
import { useDashboard } from "../../useDashboard";
import { toast } from "react-toastify";

import { processOpts, errorOpts, successOpts } from "../../../../util/toast";

import useDevice from "../../../../hooks/useDevice";

export const ACTION_FAV = "favorite";
export const ACTION_SHARE = "share";
export const ACTION_SAVE = "save";
export const ACTION_TAG = "tag";
export const ACTION_CLICK = "click";
export const ACTION_DELETE = "delete";

export const ACTION_EDIT = "edit";

export const DEFAULT_IMG = "https://cdn.bookaible.ai/logo/icon-bg.svg";

let lock = false;

async function getAllTags() {
  const { getToken } = await import("../../../../util/firebase");

  const token = (await getToken()).token;
  const URL = `${import.meta.env["PUBLIC_BOOK_API"]}/tags`;
  const headers = {
    Accept: "application/json",
    "X-Firebase-AppCheck": token,
  };

  const tags = await fetch(URL, {
    method: "GET",
    headers: headers,
  });

  const t = (await tags.json())["tags"];

  return t;
}

export default function Repeater({ book }) {
  const action = useRef(null);
  const toastId = useRef(null);

  const [imgSrc, setImgSrc] = useState(DEFAULT_IMG);

  const [open, setOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [masterTags, setMasterTags] = useState(null);

  const {
    location: { navigate },
    currentUser: { details },
  } = useDashboard();

  let timeoutFn;

  async function onAction(evt, type) {
    console.log(type);

    // console.log(type);
    evt.preventDefault();
    const { db } = await import("../../../../util/firebase");
    const { updateDoc, doc, serverTimestamp } = await import(
      "firebase/firestore"
    );

    if (type !== ACTION_EDIT && type !== ACTION_CLICK) {
      lock = true;
    }

    if (type === ACTION_EDIT || type === ACTION_CLICK) {
      if (lock) return;
      navigate(`/books/${book?.id}`);
      return;
    }

    if (timeoutFn) {
      clearTimeout(timeoutFn);
      timeoutFn = null;
    }
    if (type === ACTION_FAV) {
      lock = true;
      toastId.current = toast("Please wait while we process your request.", {
        ...processOpts,
      });

      try {
        // throw Error("ABC")
        await updateDoc(doc(db, "books", book?.id), {
          [ACTION_FAV]: !book[ACTION_FAV],
          updatedAt: serverTimestamp(),
        });

        toast.update(toastId.current, {
          render: `We have ${book[ACTION_FAV] ? "unmarked" : "marked"} your story book as favorite.`,
          ...successOpts,
        });
        lock = false;
      } catch (err) {
        console.error("[ERROR]", err);
        toast.update(toastId.current, {
          render: `We could not ${book[ACTION_FAV] ? "unmark" : "mark"} your story book as favorite due to some error. Please try again and if this error persists contact us at support@bookaible.ai`,
          ...errorOpts,
        });

        setTimeout(() => (lock = false), 3000);
      } finally {
      }
    } else if (type === ACTION_SHARE) {
      lock = true;

      setOpen(true);

      setTimeout(() => (lock = false), 3000);
    } else if (type === ACTION_SAVE) {
      console.log(type);
      lock = true;

      setDownloadOpen(true);

      setTimeout(() => (lock = false), 3000);
    } else if (type === ACTION_TAG) {
      lock = true;

      setTagOpen(true);

      setTimeout(() => (lock = false), 3000);
    } else if (type === ACTION_DELETE) {
      lock = true;
      toastId.current = toast("Please wait while we process your request.", {
        ...processOpts,
      });
      const { getToken, auth } = await import("../../../../util/firebase");

      let bearer = await auth.currentUser
        .getIdToken(true)
        .then((idToken) => idToken);

      const token = (await getToken()).token;
      const URL = `${import.meta.env["PUBLIC_BOOK_API"]}/book/${book?.id}`;
      const headers = {
        Authorization: `Bearer ${bearer}`,
        "X-Firebase-AppCheck": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const res = await fetch(URL, {
        method: "DELETE",
        headers: headers,
      });

      
      toast.update(toastId.current, {
        ...successOpts,
        render: "We have removed the book from your account."
      })

    }
  }

  console.log({ downloadOpen });

  async function getImage(img_ref, cover = false) {

    const { onSnapshot } = await import("firebase/firestore");
    const { storage } = await import("../../../../util/firebase");
    const { ref, getDownloadURL } = await import("firebase/storage");
    
	
	onSnapshot(img_ref, (snapshot) => {
      if (!snapshot.exists()) {
        return false;
      }

      const data = snapshot.data({
        serverTimestamps: "estimate",
      });

      if (data["status"] !== "COMPLETED") {
        setImgSrc(DEFAULT_IMG);
        return false;
      }

      if (
        cover === false &&
        "arr" in data &&
        Array.isArray(data["arr"]) &&
        data["arr"].length > 0
      ) {
        setImgSrc(data["arr"][0]["url"]);
      }

      if (cover === true) {
        // console.log(data['top_cover'])
        setImgSrc(data["top_cover"]["url"]);
      }
    });
  }

  const device = useDevice();

  useEffect(() => {
    getAllTags().then((allTags) => {
      setMasterTags(allTags);
      return true;
    });
  }, []);

  useEffect(() => {
    if (typeof book === "object" && book["stage"] !== "STORY") {
      const isCover =
        book["stage"] === "COVER" ||
        book["stage"] === "BOOK" ||
        book["stage"] === "END";
      getImage(book["image"], isCover);
    }

    return () => false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book]);

  const linkRef = useRef(null);

  const [linkWidth, setWidth] = useState("300px");

  useEffect(() => {
    if (device === "phone" && linkRef !== null) {
      setWidth(linkRef.current.offsetWidth - 100 + "px");
    }
  }, [linkRef, device]);

  // console.log(linkWidth);

  if (device !== "phone") {
    return (
      <>
        <div
          role="link"
          onClick={(evt) => onAction(evt, ACTION_CLICK)}
          aria-description={`Visit your storybook - ${book.title}`}
          className="flex w-[95%] cursor-pointer flex-row justify-between rounded-xl bg-light-white p-3 py-5"
        >
          <div className="ml-4 flex flex-row items-center gap-10">
            <img src={imgSrc} className="aspect-square w-11 rounded-md" />
            <div className="flex flex-col">
              <p className="font-dm text-sm font-bold leading-normal text-primary/70">
                {book?.title}
              </p>
              <p className="font-dm text-[1.1rem] font-[500] text-light-black">
                {book?.moral}
              </p>
            </div>
          </div>
          <div
            className="mr-12 flex flex-row items-center justify-evenly gap-6"
            ref={action}
          >
            {book.status === 2 && (
              <>
                <button
                  className="flex-col items-center justify-center rounded-md outline-none"
                  onClick={(evt) => onAction(evt, ACTION_TAG)}
                >
                  <div className="flex items-center justify-center rounded-md bg-secondary px-4 py-2 font-dm font-bold text-primary">
                    {"tags" in book &&
                    "list" in book["tags"] &&
                    Array.isArray(book["tags"]["list"]) &&
                    Array.from(book["tags"]["list"]).length > 0
                      ? Array.from(book["tags"]["list"])[0]
                      : "Uncategorized"}
                  </div>
                </button>

                <button
                  className="justify-center-rounded-md flex flex-col items-center px-2 py-2 outline-none hover:duration-500 hover:ease-in-out"
                  onClick={(evt) => onAction(evt, ACTION_FAV)}
                >
                  <svg
                    width="24"
                    height="22"
                    viewBox="0 0 24 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.4893 1.94587L20.4906 1.94668C22.7418 3.34584 24.3356 6.99312 21.421 11.9311C21.2767 12.1626 18.4529 16.6103 11.9995 20.6482C5.63696 16.6719 2.86322 12.3958 2.57678 11.9362C-0.335002 6.99454 1.25872 3.34515 3.5085 1.94663L3.51004 1.94568C4.26852 1.47228 5.15258 1.21985 6.06944 1.21985C7.86902 1.21985 9.66778 2.19902 11.0999 4.18092L11.1886 4.30393L11.9997 5.42851L12.8108 4.30392L12.8999 4.18037C12.8999 4.1803 12.9 4.18024 12.9 4.18017C14.3297 2.19893 16.1274 1.21985 17.9277 1.21985C18.8447 1.21985 19.73 1.47236 20.4893 1.94587Z"
                      strokeWidth="2"
                      stroke="#EF6183"
                      fill="transparent"
                      className={`transition-all duration-200 ease-linear hover:fill-red-400 ${
                        book?.favorite === true
                          ? "fill-[#EF6183] !stroke-[#EF6183]"
                          : ""
                      }`}
                    />
                  </svg>
                </button>
                <button
                  className={`${book["downloadable"] === true ? "flex" : "hidden"} flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out`}
                  onClick={(evt) => onAction(evt, ACTION_SAVE)}
                >
                  {/* Download */}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="fill-theme/[65%] transition-all duration-300 ease-linear hover:fill-theme"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20.5996 13.6C20.3344 13.6 20.08 13.7054 19.8925 13.8929C19.705 14.0804 19.5996 14.3348 19.5996 14.6V18.6C19.5996 18.8652 19.4942 19.1196 19.3067 19.3071C19.1192 19.4946 18.8648 19.6 18.5996 19.6H4.59961C4.33439 19.6 4.08004 19.4946 3.8925 19.3071C3.70497 19.1196 3.59961 18.8652 3.59961 18.6V14.6C3.59961 14.3348 3.49425 14.0804 3.30672 13.8929C3.11918 13.7054 2.86483 13.6 2.59961 13.6C2.33439 13.6 2.08004 13.7054 1.8925 13.8929C1.70497 14.0804 1.59961 14.3348 1.59961 14.6V18.6C1.59961 19.3957 1.91568 20.1587 2.47829 20.7213C3.0409 21.2839 3.80396 21.6 4.59961 21.6H18.5996C19.3953 21.6 20.1583 21.2839 20.7209 20.7213C21.2835 20.1587 21.5996 19.3957 21.5996 18.6V14.6C21.5996 14.3348 21.4942 14.0804 21.3067 13.8929C21.1192 13.7054 20.8648 13.6 20.5996 13.6ZM10.8896 15.31C10.9847 15.401 11.0969 15.4724 11.2196 15.52C11.3393 15.5729 11.4687 15.6002 11.5996 15.6002C11.7305 15.6002 11.8599 15.5729 11.9796 15.52C12.1024 15.4724 12.2145 15.401 12.3096 15.31L16.3096 11.31C16.4979 11.1217 16.6037 10.8663 16.6037 10.6C16.6037 10.3337 16.4979 10.0783 16.3096 9.89C16.1213 9.7017 15.8659 9.59591 15.5996 9.59591C15.3333 9.59591 15.0779 9.7017 14.8896 9.89L12.5996 12.19V2.60001C12.5996 2.33479 12.4943 2.08044 12.3067 1.8929C12.1192 1.70536 11.8648 1.60001 11.5996 1.60001C11.3344 1.60001 11.08 1.70536 10.8925 1.8929C10.705 2.08044 10.5996 2.33479 10.5996 2.60001V12.19L8.30961 9.89C8.21637 9.79677 8.10568 9.72281 7.98386 9.67234C7.86204 9.62188 7.73147 9.59591 7.59961 9.59591C7.46775 9.59591 7.33718 9.62188 7.21536 9.67234C7.09354 9.72281 6.98285 9.79677 6.88961 9.89C6.79637 9.98324 6.72241 10.0939 6.67195 10.2158C6.62149 10.3376 6.59552 10.4681 6.59552 10.6C6.59552 10.7319 6.62149 10.8624 6.67195 10.9843C6.72241 11.1061 6.79637 11.2168 6.88961 11.31L10.8896 15.31Z" />
                  </svg>
                </button>

                <button
                  className="flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
                  onClick={(evt) => onAction(evt, ACTION_SHARE)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="fill-theme/[65%] transition-all duration-300 ease-linear hover:fill-theme"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.107 11.4928L14.107 3.4928C13.9672 3.35299 13.789 3.25778 13.5951 3.21922C13.4011 3.18065 13.2001 3.20045 13.0174 3.27612C12.8347 3.35179 12.6785 3.47993 12.5686 3.64434C12.4587 3.80875 12.4001 4.00205 12.4 4.1998V7.7448C9.66832 7.99759 7.12925 9.26066 5.27971 11.2868C3.43018 13.313 2.4033 15.9564 2.40002 18.6998V20.1998C2.40018 20.4074 2.46493 20.6098 2.5853 20.7789C2.70566 20.948 2.87567 21.0755 3.07175 21.1436C3.26783 21.2118 3.48025 21.2172 3.67955 21.1592C3.87886 21.1011 4.05517 20.9825 4.18402 20.8198C5.16372 19.6547 6.36578 18.6966 7.71997 18.0014C9.07416 17.3062 10.5533 16.8879 12.071 16.7708C12.121 16.7648 12.246 16.7548 12.4 16.7448V20.1998C12.4001 20.3975 12.4587 20.5908 12.5686 20.7553C12.6785 20.9197 12.8347 21.0478 13.0174 21.1235C13.2001 21.1991 13.4011 21.2189 13.5951 21.1804C13.789 21.1418 13.9672 21.0466 14.107 20.9068L22.107 12.9068C22.2945 12.7193 22.3998 12.465 22.3998 12.1998C22.3998 11.9346 22.2945 11.6803 22.107 11.4928ZM14.4 17.7858V15.6998C14.4 15.4346 14.2947 15.1802 14.1071 14.9927C13.9196 14.8052 13.6652 14.6998 13.4 14.6998C13.145 14.6998 12.104 14.7498 11.838 14.7848C9.14289 15.0331 6.57744 16.0571 4.45202 17.7328C4.69327 15.5272 5.73956 13.4881 7.39058 12.006C9.04159 10.5238 11.1813 9.70264 13.4 9.6998C13.6652 9.6998 13.9196 9.59444 14.1071 9.4069C14.2947 9.21937 14.4 8.96501 14.4 8.6998V6.6138L19.986 12.1998L14.4 17.7858Z" />
                  </svg>
                </button>
              </>
            )}

            {book.status === 1 && (
              <>
                <button
                  className="flex flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
                  onClick={(evt) => onAction(evt, ACTION_EDIT)}
                >
                  {/* Edit */}
                  <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    className="fill-theme/[90%] transition-all duration-300 ease-linear hover:fill-black"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.6021 22.7406H5.39809C5.02483 22.7406 4.70898 23.0564 4.70898 23.4297C4.70898 23.803 5.02483 24.1188 5.39809 24.1188H23.6021C23.9753 24.1188 24.2912 23.803 24.2912 23.4297C24.2912 23.0564 23.9753 22.7406 23.6021 22.7406ZM4.7377 16.7109L4.70898 19.9554C4.70898 20.1277 4.76641 20.3287 4.90997 20.4436C5.05354 20.5584 5.22582 20.6445 5.39809 20.6445L8.64265 20.6158C8.81493 20.6158 8.98721 20.5297 9.13077 20.4148L20.2714 9.27425C20.5298 9.01584 20.5298 8.55643 20.2714 8.29802L17.0555 5.08218C16.7971 4.82376 16.3377 4.82376 16.0793 5.08218L13.8397 7.32178L4.93869 16.2228C4.79512 16.3663 4.7377 16.5386 4.7377 16.7109ZM18.807 8.78614L17.5436 10.0495L15.304 7.8099L16.5674 6.54653L18.807 8.78614ZM6.11592 16.998L14.3278 8.78614L16.5674 11.0257L8.35552 19.2376H6.11592V16.998Z" />
                  </svg>
                </button>
                <button
                  className="flex flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
                  onClick={(evt) => onAction(evt, ACTION_DELETE)}
                >
                  {/* Delete */}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-theme/[90%] transition-all duration-300 ease-linear hover:fill-black"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.5 3.75C1.08579 3.75 0.75 4.08579 0.75 4.5C0.75 4.91421 1.08579 5.25 1.5 5.25V3.75ZM22.5 5.25C22.9142 5.25 23.25 4.91421 23.25 4.5C23.25 4.08579 22.9142 3.75 22.5 3.75V5.25ZM1.5 5.25H22.5V3.75H1.5V5.25Z" />
                    <path d="M9.75 1.5V0.75V1.5ZM8.25 3H7.5H8.25ZM7.5 4.5C7.5 4.91421 7.83579 5.25 8.25 5.25C8.66421 5.25 9 4.91421 9 4.5H7.5ZM15 4.5C15 4.91421 15.3358 5.25 15.75 5.25C16.1642 5.25 16.5 4.91421 16.5 4.5H15ZM15.75 3H16.5H15.75ZM14.25 0.75H9.75V2.25H14.25V0.75ZM9.75 0.75C9.15326 0.75 8.58097 0.987053 8.15901 1.40901L9.21967 2.46967C9.36032 2.32902 9.55109 2.25 9.75 2.25V0.75ZM8.15901 1.40901C7.73705 1.83097 7.5 2.40326 7.5 3H9C9 2.80109 9.07902 2.61032 9.21967 2.46967L8.15901 1.40901ZM7.5 3V4.5H9V3H7.5ZM16.5 4.5V3H15V4.5H16.5ZM16.5 3C16.5 2.40326 16.2629 1.83097 15.841 1.40901L14.7803 2.46967C14.921 2.61032 15 2.80109 15 3H16.5ZM15.841 1.40901C15.419 0.987053 14.8467 0.75 14.25 0.75V2.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L15.841 1.40901Z" />
                    <path d="M9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25H9ZM10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75H10.5ZM10.5 17.25V9.75H9V17.25H10.5Z" />
                    <path d="M13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25H13.5ZM15 9.75C15 9.33579 14.6642 9 14.25 9C13.8358 9 13.5 9.33579 13.5 9.75H15ZM15 17.25V9.75H13.5V17.25H15Z" />
                    <path d="M18.865 21.124L18.1176 21.0617L18.1176 21.062L18.865 21.124ZM17.37 22.5L17.3701 21.75H17.37V22.5ZM6.631 22.5V21.75H6.63093L6.631 22.5ZM5.136 21.124L5.88343 21.062L5.88341 21.0617L5.136 21.124ZM4.49741 4.43769C4.46299 4.0249 4.10047 3.71818 3.68769 3.75259C3.2749 3.78701 2.96818 4.14953 3.00259 4.56231L4.49741 4.43769ZM20.9974 4.56227C21.0318 4.14949 20.7251 3.78698 20.3123 3.75259C19.8995 3.7182 19.537 4.02495 19.5026 4.43773L20.9974 4.56227ZM18.1176 21.062C18.102 21.2495 18.0165 21.4244 17.878 21.5518L18.8939 22.6555C19.3093 22.2732 19.5658 21.7486 19.6124 21.186L18.1176 21.062ZM17.878 21.5518C17.7396 21.6793 17.5583 21.75 17.3701 21.75L17.3699 23.25C17.9345 23.25 18.4785 23.0379 18.8939 22.6555L17.878 21.5518ZM17.37 21.75H6.631V23.25H17.37V21.75ZM6.63093 21.75C6.44274 21.75 6.26142 21.6793 6.12295 21.5518L5.10713 22.6555C5.52253 23.0379 6.06649 23.25 6.63107 23.25L6.63093 21.75ZM6.12295 21.5518C5.98449 21.4244 5.89899 21.2495 5.88343 21.062L4.38857 21.186C4.43524 21.7486 4.69172 22.2732 5.10713 22.6555L6.12295 21.5518ZM5.88341 21.0617L4.49741 4.43769L3.00259 4.56231L4.38859 21.1863L5.88341 21.0617ZM19.5026 4.43773L18.1176 21.0617L19.6124 21.1863L20.9974 4.56227L19.5026 4.43773Z" />
                  </svg>
                </button>
              </>
            )}
          </div>
        </div>
        <Dialog
          book={book}
          user={details}
          open={open}
          cb={() => setOpen(false)}
        />

        <Download
          book={book}
          open={downloadOpen}
          cb={() => setDownloadOpen(false)}
        />

        {masterTags && (
          <Tags
            book={book}
            open={tagOpen}
            cb={() => setTagOpen(false)}
            masterTags={masterTags}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        role="link"
        onClick={(evt) => onAction(evt, ACTION_CLICK)}
        aria-description={`Visit your storybook - ${book.title}`}
        className="flex w-[95%] cursor-pointer flex-col justify-between gap-2 rounded-xl bg-light-white p-3"
        ref={linkRef}
      >
        <div className="ml-1 flex flex-col gap-4">
          <img
            src={imgSrc}
            className={`h-20 rounded-md ${imgSrc !== DEFAULT_IMG ? "w-full object-cover object-center" : "object-contain"}`}
          />
          <div className="flex flex-grow flex-col gap-1">
            <p className="w-full overflow-hidden font-dm text-sm font-bold leading-normal text-primary/70">
              {book?.title}
            </p>
            <p className="w-full overflow-hidden font-dm text-[1.1rem] font-[500] text-light-black">
              {book?.moral}
            </p>
          </div>
        </div>
        <div className="my-2 h-[0.2px] w-full bg-theme"></div>
        <div className="mr-5 flex flex-row justify-between gap-10">
          {book.status === 2 && (
            <>
              <button
                className="flex-col items-center justify-center rounded-md outline-none"
                onClick={(evt) => onAction(evt, ACTION_TAG)}
              >
                <div className="flex items-center justify-center rounded-md bg-secondary px-4 py-2 font-dm font-bold text-primary">
                  {"tags" in book &&
                  "list" in book["tags"] &&
                  Array.isArray(book["tags"]["list"]) &&
                  Array.from(book["tags"]["list"]).length > 0
                    ? Array.from(book["tags"]["list"])[0]
                    : "Uncategorized"}
                </div>
              </button>
              <div className="flex flex-row justify-evenly gap-6">
                <button
                  className="flex flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:duration-500 hover:ease-in-out"
                  onClick={(evt) => onAction(evt, ACTION_FAV)}
                >
                  <svg
                    width="24"
                    height="22"
                    viewBox="0 0 24 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.4893 1.94587L20.4906 1.94668C22.7418 3.34584 24.3356 6.99312 21.421 11.9311C21.2767 12.1626 18.4529 16.6103 11.9995 20.6482C5.63696 16.6719 2.86322 12.3958 2.57678 11.9362C-0.335002 6.99454 1.25872 3.34515 3.5085 1.94663L3.51004 1.94568C4.26852 1.47228 5.15258 1.21985 6.06944 1.21985C7.86902 1.21985 9.66778 2.19902 11.0999 4.18092L11.1886 4.30393L11.9997 5.42851L12.8108 4.30392L12.8999 4.18037C12.8999 4.1803 12.9 4.18024 12.9 4.18017C14.3297 2.19893 16.1274 1.21985 17.9277 1.21985C18.8447 1.21985 19.73 1.47236 20.4893 1.94587Z"
                      strokeWidth="2"
                      stroke="#EF6183"
                      fill="transparent"
                      className={`transition-all duration-200 ease-linear hover:fill-red-400 ${
                        book?.favorite === true
                          ? "fill-[#EF6183] !stroke-[#EF6183]"
                          : ""
                      }`}
                    />
                  </svg>
                </button>
                <button
                  className={`${book["downloadable"] === true ? "flex" : "hidden"} flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out`}
                  onClick={(evt) => onAction(evt, ACTION_SAVE)}
                >
                  {/* Download */}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="fill-theme/[65%] transition-all duration-300 ease-linear hover:fill-theme"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20.5996 13.6C20.3344 13.6 20.08 13.7054 19.8925 13.8929C19.705 14.0804 19.5996 14.3348 19.5996 14.6V18.6C19.5996 18.8652 19.4942 19.1196 19.3067 19.3071C19.1192 19.4946 18.8648 19.6 18.5996 19.6H4.59961C4.33439 19.6 4.08004 19.4946 3.8925 19.3071C3.70497 19.1196 3.59961 18.8652 3.59961 18.6V14.6C3.59961 14.3348 3.49425 14.0804 3.30672 13.8929C3.11918 13.7054 2.86483 13.6 2.59961 13.6C2.33439 13.6 2.08004 13.7054 1.8925 13.8929C1.70497 14.0804 1.59961 14.3348 1.59961 14.6V18.6C1.59961 19.3957 1.91568 20.1587 2.47829 20.7213C3.0409 21.2839 3.80396 21.6 4.59961 21.6H18.5996C19.3953 21.6 20.1583 21.2839 20.7209 20.7213C21.2835 20.1587 21.5996 19.3957 21.5996 18.6V14.6C21.5996 14.3348 21.4942 14.0804 21.3067 13.8929C21.1192 13.7054 20.8648 13.6 20.5996 13.6ZM10.8896 15.31C10.9847 15.401 11.0969 15.4724 11.2196 15.52C11.3393 15.5729 11.4687 15.6002 11.5996 15.6002C11.7305 15.6002 11.8599 15.5729 11.9796 15.52C12.1024 15.4724 12.2145 15.401 12.3096 15.31L16.3096 11.31C16.4979 11.1217 16.6037 10.8663 16.6037 10.6C16.6037 10.3337 16.4979 10.0783 16.3096 9.89C16.1213 9.7017 15.8659 9.59591 15.5996 9.59591C15.3333 9.59591 15.0779 9.7017 14.8896 9.89L12.5996 12.19V2.60001C12.5996 2.33479 12.4943 2.08044 12.3067 1.8929C12.1192 1.70536 11.8648 1.60001 11.5996 1.60001C11.3344 1.60001 11.08 1.70536 10.8925 1.8929C10.705 2.08044 10.5996 2.33479 10.5996 2.60001V12.19L8.30961 9.89C8.21637 9.79677 8.10568 9.72281 7.98386 9.67234C7.86204 9.62188 7.73147 9.59591 7.59961 9.59591C7.46775 9.59591 7.33718 9.62188 7.21536 9.67234C7.09354 9.72281 6.98285 9.79677 6.88961 9.89C6.79637 9.98324 6.72241 10.0939 6.67195 10.2158C6.62149 10.3376 6.59552 10.4681 6.59552 10.6C6.59552 10.7319 6.62149 10.8624 6.67195 10.9843C6.72241 11.1061 6.79637 11.2168 6.88961 11.31L10.8896 15.31Z" />
                  </svg>
                </button>
                <button
                  className="flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
                  onClick={(event) => onAction(event, ACTION_SHARE)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="fill-theme/[65%] transition-all duration-300 ease-linear hover:fill-theme"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.107 11.4928L14.107 3.4928C13.9672 3.35299 13.789 3.25778 13.5951 3.21922C13.4011 3.18065 13.2001 3.20045 13.0174 3.27612C12.8347 3.35179 12.6785 3.47993 12.5686 3.64434C12.4587 3.80875 12.4001 4.00205 12.4 4.1998V7.7448C9.66832 7.99759 7.12925 9.26066 5.27971 11.2868C3.43018 13.313 2.4033 15.9564 2.40002 18.6998V20.1998C2.40018 20.4074 2.46493 20.6098 2.5853 20.7789C2.70566 20.948 2.87567 21.0755 3.07175 21.1436C3.26783 21.2118 3.48025 21.2172 3.67955 21.1592C3.87886 21.1011 4.05517 20.9825 4.18402 20.8198C5.16372 19.6547 6.36578 18.6966 7.71997 18.0014C9.07416 17.3062 10.5533 16.8879 12.071 16.7708C12.121 16.7648 12.246 16.7548 12.4 16.7448V20.1998C12.4001 20.3975 12.4587 20.5908 12.5686 20.7553C12.6785 20.9197 12.8347 21.0478 13.0174 21.1235C13.2001 21.1991 13.4011 21.2189 13.5951 21.1804C13.789 21.1418 13.9672 21.0466 14.107 20.9068L22.107 12.9068C22.2945 12.7193 22.3998 12.465 22.3998 12.1998C22.3998 11.9346 22.2945 11.6803 22.107 11.4928ZM14.4 17.7858V15.6998C14.4 15.4346 14.2947 15.1802 14.1071 14.9927C13.9196 14.8052 13.6652 14.6998 13.4 14.6998C13.145 14.6998 12.104 14.7498 11.838 14.7848C9.14289 15.0331 6.57744 16.0571 4.45202 17.7328C4.69327 15.5272 5.73956 13.4881 7.39058 12.006C9.04159 10.5238 11.1813 9.70264 13.4 9.6998C13.6652 9.6998 13.9196 9.59444 14.1071 9.4069C14.2947 9.21937 14.4 8.96501 14.4 8.6998V6.6138L19.986 12.1998L14.4 17.7858Z" />
                  </svg>
                </button>
              </div>
            </>
          )}
          {book.status === 1 && (
            <button
              className="flex flex-col items-center justify-center rounded-md px-2 py-2 outline-none hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
              onClick={(evt) => onAction(evt, ACTION_EDIT)}
            >
              {/* Pencil */}
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="currentColor"
                className="fill-theme transition-all duration-300 ease-linear"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.6021 22.7406H5.39809C5.02483 22.7406 4.70898 23.0564 4.70898 23.4297C4.70898 23.803 5.02483 24.1188 5.39809 24.1188H23.6021C23.9753 24.1188 24.2912 23.803 24.2912 23.4297C24.2912 23.0564 23.9753 22.7406 23.6021 22.7406ZM4.7377 16.7109L4.70898 19.9554C4.70898 20.1277 4.76641 20.3287 4.90997 20.4436C5.05354 20.5584 5.22582 20.6445 5.39809 20.6445L8.64265 20.6158C8.81493 20.6158 8.98721 20.5297 9.13077 20.4148L20.2714 9.27425C20.5298 9.01584 20.5298 8.55643 20.2714 8.29802L17.0555 5.08218C16.7971 4.82376 16.3377 4.82376 16.0793 5.08218L13.8397 7.32178L4.93869 16.2228C4.79512 16.3663 4.7377 16.5386 4.7377 16.7109ZM18.807 8.78614L17.5436 10.0495L15.304 7.8099L16.5674 6.54653L18.807 8.78614ZM6.11592 16.998L14.3278 8.78614L16.5674 11.0257L8.35552 19.2376H6.11592V16.998Z"
                  fill="inherit"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <Dialog
        book={book}
        user={details}
        open={open}
        cb={() => setOpen(false)}
      />

      <Download
        book={book}
        open={downloadOpen}
        cb={() => setDownloadOpen(false)}
      />

      {masterTags && (
        <Tags
          book={book}
          open={tagOpen}
          cb={() => setTagOpen(false)}
          masterTags={masterTags}
        />
      )}
    </>
  );
}

import Snackbar from "node-snackbar";

import FB from "../../../../icons/fb-share.svg?react";
import IT from "../../../../icons/insta-share.svg?react";
import QR from "../../../../icons/qr-share.svg?react";
import TW from "../../../../icons/x-share.svg?react";
import MORE from "../../../../icons/more.svg?react";
import X from "../../../../icons/times.svg?react";
import COPY from "../../../../icons/copy.svg?react";
import COPYBIG from "../../../../icons/copy-icon.svg?react";
import EYE from "../../../../icons/eye.svg?react";
import EYES from "../../../../icons/eye-slash.svg?react";
import EYEW from "../../../../icons/eye-white.svg?react";
import EYESW from "../../../../icons/eye-slash-white.svg?react";
import { Tooltip } from "react-tooltip";

import QRCode from "react-qr-code";

const winparams =
  "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0";

function Dialog({ book, details, open, cb = undefined }) {
  if (typeof details === "undefined") {
    details = { role: "user" };
  }

  const link = [
    window.location.protocol,
    "",
    window.location.host,
    "books",
    book.id,
  ].join("/");

  const [qr, setQR] = useState(false);

  const writeClip = async (element) => {
    element.target.disabled = true;
    try {
      await navigator.clipboard.writeText(link);
      Snackbar.show({
        showAction: false,
        text: "Copied link to clipboard",
        pos: "bottom-center",
        duration: 3000,
        onClose: () => (element.target.disabled = false),
      });
    } catch (err) {
      // console.log(err, err?.message);
      element.target.disabled = false;
    }
  };

  const shareMore = async () => {
    const shareData = {
      title: book["title"],
      text: `Read my new storybook create by bookaible.ai`,
      url: link,
    };

    await navigator.share(shareData);
  };

  const shareTW = () => {
    let shareURL = "http://twitter.com/share?"; //url
    let params = {
      url: link,
      text: `Read my story book named ${book["title"]} created by @bookaibleai`,
      hashtags: "bookaibleai",
    };
    for (let prop in params) {
      shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
    }

    window.open(shareURL, "", winparams);
  };

  const doShare = async (evt) => {
    const target = evt.target;
    target.disabled = true;

    const visibility = book["visibility"] !== "public" ? "public" : "private";
    const isNotPublic = book["visibility"] !== "public";

    let toastId = toast(
      `Please wait while we prep your storybook to be ${isNotPublic ? "shared" : "un-shared"} with the world${details["role"] === "admin" ? " and the bookaible library" : ""}!`,
      {
        ...processOpts,
      },
    );

    const { auth, getToken } = await import("../../../../util/firebase");

    let token = (await getToken()).token;

    let bearer = await auth.currentUser
      .getIdToken(true)
      .then((idToken) => idToken);

    const decoded = JSON.parse(window.atob(bearer.split(".")[1]));

    if (
      typeof decoded === "object" &&
      "role" in decoded &&
      typeof decoded["role"] === "string"
    ) {
      details = { role: decoded["role"] };
    }

    const URL = `${import.meta.env["PUBLIC_BOOK_API"]}/book/${book.id}/share`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": token,
      Authorization: `Bearer ${bearer}`,
    };

    // console.log(details["role"]);

    let successMessage = isNotPublic
      ? `Thank you for sharing the book with the world${details["role"] === "admin" ? " and the bookaible library" : ""}!`
      : `We have successfully unshared the book with the world${details["role"] === "admin" ? " and the bookaible library" : ""}!`;

    try {
      let res = await fetch(URL, {
        method: "POST",
        body: JSON.stringify({ visibility }),
        headers,
      });

      if (res.ok) {
        toast.update(toastId, {
          render: successMessage,
          ...successOpts,
        });
      } else {
        toast.update(toastId, {
          render:
            "Sorry something went wrong while doing this operation. Please try again. If this error persists, please contact support@bookaible.ai",
          ...errorOpts,
        });
      }
    } catch (err) {
      // console.log(err);

      toast.update(toastId, {
        render:
          "Sorry something went wrong while doing this operation. Please try again. If this error persists, please contact support@bookaible.ai",
        ...errorOpts,
      });
    } finally {
      target.disabled = !true;
    }
  };

  function closeDialog() {
    if (dialog.current.open) {
      dialog.current.close();
    }

    if (typeof cb === "function") {
      cb();
    }

    return true;
  }

  const shareFB = () => {
    let data = {
      app_id: import.meta.env.PUBLIC_FB_APP_ID,
      display: "popup",
      href: link,
      hashtags: "#bookaibleai",
      redirect_uri: "https://api.bookable.ai",
    };

    let arr = [];

    for (let [key, value] of Object.entries(data)) {
      arr.push(`${key}=${encodeURIComponent(value)}`);
    }

    let URL = `https://www.facebook.com/dialog/share?${arr.join("&")}`;

    // console.log(URL);

    window.open(URL, "", winparams);
  };

  const [switchValue, setSwitchValue] = useState(
    (book["visibility"] ?? "private") === "public",
  );

  const dialog = useRef(null);
  const more = useRef(null);

  const [isMore, setIsMore] = useState(false);

  useEffect(() => {
    if (!book || !details || !link) return () => false;
    const shareData = {
      title: book["title"],
      text: `Read my new storybook create by bookaible.ai`,
      url: link,
    };

    if (
      "share" in navigator &&
      "canShare" in navigator &&
      navigator.canShare(shareData)
    ) {
      setIsMore(true);
    }
  }, [details, book, link]);

  return (
    <dialog
      className="fixed bottom-0 top-0 z-50 block h-full max-h-full w-full max-w-full scale-0 overflow-hidden outline-0 transition-all delay-[200ms] duration-200 ease-linear open:scale-100 open:bg-black/40 open:delay-0"
      ref={dialog}
      open={open}
    >
      <div
        className={`relative left-[50%] top-[50%] flex h-fit max-h-full w-[calc(100%-1rem)] -translate-x-[50%] -translate-y-[50%] flex-col gap-5 rounded-md bg-white px-5 py-4 transition-[height] duration-200 ease-in-out md:w-[65%] lg:w-[50%]`}
      >
        <div className="flex flex-row justify-between">
          <h5 className="font-dm font-medium">
            Share &ldquo;{book?.title}&rdquo;
          </h5>
          <button
            onClick={closeDialog}
            role="button"
            className="rounded-full p-3 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
          >
            <X />
          </button>
        </div>
        <div className="mx-2 grid grid-cols-2 items-center justify-between md:flex md:flex-row">
          <button
            role="button"
            className="flex min-w-[6.25rem] flex-col items-center justify-center gap-2 rounded-md p-2 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
          >
            <IT />
            <p>Instagram</p>
          </button>
          <button
            className="flex min-w-[6.25rem] flex-col items-center justify-center gap-2 rounded-md p-2 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
            onClick={shareFB}
          >
            <FB />
            <p>Facebook</p>
          </button>
          <button
            className="flex min-w-[6.25rem] flex-col items-center justify-center gap-2 rounded-md p-2 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
            onClick={shareTW}
          >
            <TW />
            <p>X</p>
          </button>
          <button
            className={`flex min-w-[6.25rem] flex-col items-center justify-center gap-2 rounded-md p-2 ${!qr ? "hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out" : "bg-alt-gray-200"}`}
            onClick={() => setQR((e) => !e)}
          >
            <QR />
            <p>QR Scan</p>
          </button>
          <button
            className={`flex min-w-[6.25rem] flex-col items-center justify-center gap-2 rounded-md p-2 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out md:hidden`}
            onClick={writeClip}
          >
            <COPYBIG />
            <p>Copy Link</p>
          </button>
          <button
            className={`${isMore ? "flex" : "hidden"} min-w-[6.25rem] flex-col items-center justify-center gap-2 rounded-md p-2 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out`}
            onClick={shareMore}
            ref={more}
          >
            <MORE />
            <p>More</p>
          </button>
          {book["visibility"] === "public" && (
            <>
              <button
                className="primary-btn col-span-2 mx-auto mt-4 w-full !max-w-[400px] items-center justify-start disabled:bg-gray md:!hidden"
                onClick={doShare}
              >
                <EYESW id="vtew" />
                Restrict to me
              </button>
              <Tooltip anchorSelect="#vtew" place="top" className="z-20">
                Visible to Everyone
              </Tooltip>
            </>
          )}
          {book["visibility"] !== "public" && (
            <div className="col-span-2 flex md:hidden">
              <button
                className="primary-btn mx-auto mt-4 w-full !max-w-[400px] items-center justify-start disabled:bg-gray md:!hidden"
                onClick={doShare}
              >
                <EYEW id="vtyw" />
                Share Publicly
              </button>
              <Tooltip anchorSelect="#vtyw" place="top" className="z-20">
                Visible to You
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className={`relative mx-auto ${qr ? "flex" : "hidden"} w-fit flex-col items-center justify-center gap-3 rounded-md border-[1px] border-gray p-5`}
        >
          <div className="absolute aspect-square w-[4.5rem] bg-white bg-opacity-100 bg-[url('/assets/icon-bg.svg')] bg-contain bg-center"></div>
          <QRCode value={link} level="H" />
        </div>
        <div className="mx-2 hidden flex-col gap-3 md:flex">
          <p className="font-dm font-semibold">Page Link</p>
          <div className="-ml-[2px] flex flex-row items-center justify-between rounded-md bg-alt-gray-200 px-3 py-[0.35rem]">
            <p className="font-dm text-light-theme">{link}</p>
            <div className="flex flex-row items-center justify-center gap-4">
              {book["visibility"] === "public" && (
                <button
                  className="flex flex-col items-center justify-center rounded-md p-[0.3rem] hover:bg-alt-gray-300 hover:duration-500 hover:ease-in-out"
                  id="vte"
                  onClick={doShare}
                >
                  <EYES />
                  <Tooltip anchorSelect="#vte" place="top" className="z-20">
                    Visible to Everyone
                  </Tooltip>
                </button>
              )}

              {(book["visibility"] === "private" ||
                typeof book["visibility"] === "undefined") && (
                <button
                  className="flex flex-col items-center justify-center rounded-md p-[0.3rem] hover:bg-alt-gray-300 hover:duration-500 hover:ease-in-out"
                  id="vty"
                  onClick={doShare}
                >
                  <EYE />
                  <Tooltip anchorSelect="#vty" place="top" className="z-20">
                    Visible to You
                  </Tooltip>
                </button>
              )}

              <button
                className="flex flex-col items-center justify-center rounded-md p-[0.3rem] hover:bg-alt-gray-300 hover:duration-500 hover:ease-in-out"
                onClick={writeClip}
              >
                <COPY />
              </button>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
}

import PDF from "../../../../icons/pdf.svg?react";
import ZIP from "../../../../icons/zip.svg?react";
import PRINT from "../../../../icons/print.svg?react";

function Download({ book, open, cb = undefined }) {
  console.log({
    book,
    open,
  });

  function closeDialog() {
    if (dialog.current.open) {
      dialog.current.close();
    }

    if (typeof cb === "function") {
      cb();
    }

    return true;
  }

  const dialog = useRef(null);

  return (
    <dialog
      className="fixed bottom-0 top-0 z-50 block h-full max-h-full w-full max-w-full scale-0 overflow-hidden outline-0 transition-all delay-[200ms] duration-200 ease-linear open:scale-100 open:bg-black/40 open:delay-0"
      ref={dialog}
      open={open}
    >
      <div
        className={`relative left-[50%] top-[50%] flex h-fit max-h-full w-[calc(100%-1.8rem)] -translate-x-[50%] -translate-y-[50%] flex-col gap-5 rounded-md bg-white px-5 py-4 transition-[height] duration-200 ease-in-out md:w-[45%] lg:w-[35%]`}
      >
        <div className="flex flex-row justify-between">
          <h5 className="font-dm font-medium">
            Download &ldquo;{book?.title}&rdquo;
          </h5>
          <button
            onClick={closeDialog}
            role="button"
            className="rounded-full p-3 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
          >
            <X />
          </button>
        </div>
        <p className="-mt-4 mb-2">
          Choose any of the following options to download your storybook.
        </p>
        {book["pdf"] === false || book["zip"] === false ? (
          <div className="flex w-full flex-col items-center justify-center gap-2">
            <Loading />
            <p className="px-6 text-center font-roboto text-sm">
              We are still preparing the digital assets for this book.
              <br />
              This may take a moment.
            </p>
          </div>
        ) : (
          <div className="mx-2 grid grid-cols-2 items-center justify-around md:flex md:flex-row">
            <a
              role="link"
              className="flex min-w-[6.25rem] flex-col items-center justify-center gap-0 rounded-md p-2 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
              href={book["pdf"]}
            >
              <PDF />
              {/* <p>PDF</p> */}
            </a>
            <a
              role="link"
              className="flex min-w-[6.25rem] flex-col items-center justify-center gap-0 rounded-md p-2 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
              href={book["zip"]}
            >
              <ZIP />
              {/* <p>Zip</p> */}
            </a>
          </div>
        )}
      </div>
    </dialog>
  );
}

import { useCallback } from "react";
//  React-wrapper file
import "@yaireo/tagify/dist/tagify.css"; // Tag

function Tags({ book, open, cb = undefined, masterTags = [] }) {
  function closeDialog() {
    if (dialog.current.open) {
      dialog.current.close();
    }

    if (typeof cb === "function") {
      cb();
    }

    return true;
  }

  const [Tagify, setComponent] = useState(null);
  const [whitelist, setWhitelist] = useState([]);

  const dialog = useRef(null);
  const [tref, setTRef] = useState([]);

  const [ list, doc, id ] = [ [], [], [] ]

  const getTagify = async () => {
    const T = await import("@yaireo/tagify/react");

    // setMasterTags(t);

    let tt = [...Object.values(masterTags)].map((t) => t["value"]);
    
    tt.forEach( (val) => list.push(val) );

    setWhitelist(tt);
    setComponent(T.default);

    return true;

  };

  const [ cross, setCross ] = useState(true);
  
  const onClick = useCallback(async (e) => {
    e.target.disabled = true;
    setCross(false);
    try {

      console.log(tref, typeof tref);
      const tags = JSON.parse(tref).map((t) => t.value);
      const toastId = toast("Please wait while we process your request.", {
        ...processOpts,
      });
      const { getToken, auth } = await import("../../../../util/firebase");
  
      let bearer = await auth.currentUser
        .getIdToken(true)
        .then((idToken) => idToken);
  
      const token = (await getToken()).token;
      
      const URL = `${import.meta.env["PUBLIC_BOOK_API"]}/tags/${book?.id}`;
      
      const headers = {
        Authorization: `Bearer ${bearer}`,
        "X-Firebase-AppCheck": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
  
      await fetch(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          tags,
        })
      });
      
      
      
      toast.update(toastId, {
        ...successOpts,
        render: "Added tags to your book"
      })
    } catch (err) {

    } finally {
      e.target.disabled = false;
      setCross(true);
      closeDialog();
      
    }
  }, [tref])

  const onChange = useCallback((e) => {
    setTRef(e.detail.value);
  }, []);

  useEffect(() => {
    // if(Tagify || whitelist) return () +
    (async function() {
      await getTagify();
    })();

  }, []);

  return (
    <dialog
      className="fixed bottom-0 top-0 z-50 block h-full max-h-full w-full max-w-full scale-0 overflow-hidden outline-0 transition-all delay-[200ms] duration-200 ease-linear open:scale-100 open:bg-black/40 open:delay-0"
      ref={dialog}
      open={open}
    >
      <div
        className={`relative left-[50%] top-[50%] flex h-fit max-h-full w-[calc(100%-1.8rem)] -translate-x-[50%] -translate-y-[50%] flex-col gap-5 rounded-md bg-white px-5 py-4 transition-[height] duration-200 ease-in-out md:w-[55%] lg:w-[45%]`}
      >
        <div className="flex flex-row justify-between">
          <h5 className="font-dm font-medium">
            Edit Tags for &ldquo;{book?.title}&rdquo;
          </h5>
          { cross && (<button
            onClick={closeDialog}
            role="button"
            className="rounded-full p-3 hover:bg-alt-gray-200 hover:duration-500 hover:ease-in-out"
          >
            <X />
          </button>) }
          
        </div>
        {Tagify && whitelist && (
          <Tagify
            whitelist={whitelist}
            onChange={onChange}
            placeholder="Select upto 4 tags"
            settings={{
              maxTags: 4,
              dropdown: {
                enabled: 0, // always show suggestions dropdown
              },
            }}
            className="w-full rounded-md"
            defaultValue={
              typeof book["tags"] === "object" &&
              Array.isArray(book["tags"]["list"])
                ? book["tags"]["list"].join(",")
                : ""
            }
          />
        )}
        <button className="primary-btn ml-auto" onClick={onClick}>Save</button>
      </div>
    </dialog>
  );
}

function Loading({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      className={className}
      stroke="#1F2E43"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
}
