import { useEffect, useState, useContext, useReducer, createContext } from "react";
import { Loader } from '../../../components';
import { auth, db, analytics, messaging, saveMessagingDeviceToken, requestPermission } from '../../../util/firebase';
import { useAuthState } from 'react-firebase-hooks/auth'
import { useLocation, useNavigate } from 'react-router-dom';

import Snackbar from 'node-snackbar'

import { setUserId } from "firebase/analytics";
import { onMessage } from "firebase/messaging";
// eslint-disable-next-line no-unused-vars
import { collection, orderBy, where, onSnapshot, query, doc } from 'firebase/firestore';
import { useLocalStorage } from "@uidotdev/usehooks";

const DashboardContext = createContext({ currentUser: {}, location: {}, pwa: {}, payload: { books: [], completed: [], progress: [], total: [], favorite: [] } });

// eslint-disable-next-line react-refresh/only-export-components
export function useDashboard() {
    return useContext(DashboardContext);
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'NEW_BOOK':
            return [...state, {
                ...action?.payload ?? {}
            }];
        
        case 'REMOVE_BOOK': return state.filter((book) => book.id !== action.payload.id);

        case 'UPDATE_BOOK': 
        return state.map(book => {
				
            if(book.id === action.payload.id) {
            return action?.payload
            } else {
                return book
            }

        });

        case 'default':
            return state;
    }
    
}

// eslint-disable-next-line react/prop-types
export default function DashboardProvider({ children }) {
    
    const [ user, loading,  ] = useAuthState(auth)
    const [ loader, setLoader ] = useState(true);
    const [ pwa, setPWA ] = useState({
        deferredPrompt: null,
        promote: false,
    })

    const [ books, dispatch ] = useReducer(reducer, []);

    const [ userDetails, setUserDetails ] = useState({
        verified: null,
        approved: true,
        displayName: null,
        stable: false,
    });

    const [ permission, setPermission ] = useLocalStorage("notification", "default");
    const [ snackShown, setSnackShown ] = useState(false);
    const [ dismiss, setDismiss ] = useLocalStorage("snackbar", "no");
    const [ triggered, setTriggered ] = useState(false);
    const [ pwaDismiss, setPWADismiss ] = useLocalStorage("pwadismiss", "no")


    const [ apzi, setApzi ] = useState(false);
    


    // Our reducer looks into this thing
    // All the books generated by the user

    useEffect( () => {
        if(user !== null && db !== null) {
            // console.log(user.uid);
            return onSnapshot(query(collection(db, 'books'), where("uid", "==", user.uid), where("trash", "==", false), orderBy("createdAt", "desc")), (snapshot) => {
                // console.log(snapshot)
                snapshot.docChanges().forEach( (change) => {
                    let type;
                    if(change.type === 'added') {
						type = 'NEW_BOOK';
					}else if(change.type === 'modified') {
						type = 'UPDATE_BOOK'
					}else if( change.type === 'removed' ) {
						type = 'REMOVE_BOOK'
					}

                    // if(type)

					let payload = change.doc.data({
						serverTimestamps: 'estimate'
					});
                    
					let id = change.doc.id;

                    if(payload && typeof type === 'string') {

						dispatch( {type, payload: {id, ...payload}} );

                    }
                });
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, db])

    useEffect( () => {

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            
            setPWA(() => ({
                deferredPrompt: e,
                promote: true,
            }));

            // console.log(`'beforeinstallprompt' event was fired.`);
        });

        window.addEventListener('appinstalled', () => {
            
            setPWA(() => ({
                deferredPrompt: null,
                promote: false,
            }));

            // console.log('PWA was installed');
        });
    }, []);

    useEffect( () => {
        if(!loading) {
            if(!user) {
                // console.log(user);
                let next = encodeURIComponent(window.location.pathname.substring(1));
                
                if(next.length === 0) {
                    navigate(`/auth`);
                } else {
                    navigate(`/auth?next=${next}`);
                }
                
            } else {
                setUserId(analytics, user.uid)
                

                // set a realtime snapshot listener.

                return onSnapshot(doc(db, "users", user.uid), async (snapshot) => {
                    
                    if(!snapshot.exists()) {
                        return false;
                    }

                    const data = snapshot.data({
                        serverTimestamps: "estimate"
                    })

                    if(!apzi) {
                        // console.log(data.createdAt, new Date(data.createdAt.seconds))
                        window.appziSettings = {
                            'userId': data.uid,
                            data: {
                                'uid': data.uid,
                                'premium': data.subscribed,
                                'role': data.role,
                                'email': data.email,
                            },
                        }

                        setApzi(true);
                    }

                    
                    const idToken = await auth.currentUser.getIdToken(true)
                    // const decodedIdToken = await auth.currentUser.getIdTokenResult(true);
                    
                    // console.log({
                    //     decodedIdToken,
                    //     idToken
                    // })

                    setUserDetails({
                        ...data,
                        idToken,
                        stable: true,
                    });

                    if(loader) {
                        setLoader(false);
                    }
                    
                }, (err) => console.log("Something went wrong", err))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, user]);

    async function installApp() {
        if(pwa && pwa.promote && pwa.deferredPrompt) {
            let { deferredPrompt } = pwa;
            
            setPWA((prev) => ({
                ...prev,
                promote: false,
            }));

            deferredPrompt.prompt();
            
            // Wait for the user to respond to the prompt
            const { outcome } = await deferredPrompt.userChoice;

            // console.log({
            //     outcome
            // });

            console.log(`User response to the install prompt: ${outcome}`);
            
            // We've used the prompt, and can't use it again, throw it away
            // DO NOT NEED TO DO THIS AS THIS IS REACT.JS
            deferredPrompt = null;
        }
    }


    useEffect( () => {
        window.addEventListener("swupdatefound", () => {
            Snackbar.show({
                actionText: "Reload",
                onActionClick: () => window.location.reload(),
                "text": "Bookaible just got updated. Please reload to quickly get the latest version.",
            })
        })
    }, [])


    useEffect( () => {
        return onMessage(messaging, async (payload) => {

            console.log("Frontend Message recieved ", payload);

            const notificationTitle = payload.data.title;
            const options = JSON.parse(payload.data.options);
            const notificationOptions = {
                ...options,
                vibrate: (new Boolean(options.vibrate)).valueOf(),
                renotify: (new Boolean(options.renotify)).valueOf(),
                icon: "/assets/icon-bg-72.png",
            };

            console.log("Payload structured ", notificationOptions);

            // let notify = false;
            
            // if('serviceWorker' in navigator) {
            //     const registration = await navigator.serviceWorker.getRegistration();
            //     if('showNotification' in registration) {
            //         // if( document.visibilityState === ""
            //         await registration.showNotification(notificationTitle, notificationOptions);
            //         notify = true;
            //     }
            // }

            // if(!notify) {
            //     new Notification(notificationTitle, notificationOptions);
            // }
        })
    }, []);

    useEffect( () => {
        if(loader || loading || !user || permission === "denied" || triggered) return () => false;

        if(permission === "granted") {

            setTriggered(true);

            (async function(uid){
                await saveMessagingDeviceToken(uid, true);
            })(user.uid)

            return () => false;
        }

        // We will ping the user for browser permission
        if(permission === "default" && !snackShown && dismiss !== "yes") {
            // Maybe not asked
            Snackbar.show({
                actionText: "Nope",
                actionTextColor: "#fff",
                customClass: "leading-5",
                secondButtonText: "Sure",
                showAction: true,
                showSecondButton: true,
                duration: false,
                onActionClick: () => {
                    // Hit the Nope Button (OK)
                    // We will not show you anything unless otherwise specified.
                    setDismiss("yes");
                    Snackbar.close();
                    Snackbar.show({
                        "text": "Uh Oh! We won't send you push notifications. If you want to grant us permission, go to Account, Settings and toggle notifications.",
                        duration: 5000,
                        showAction: false,
                    })
                },
                onSecondButtonClick: async () => {
                    // Click Sure

                    Snackbar.close();
                    setTriggered(true);
                    
                    const permission = await requestPermission();
                    setPermission(permission);

                    if(permission === "granted") {
                        // first time
                        window.location.reload()
                        return () => false;
                    }

                    if(permission === "denied") {
                        // Uh! Oh!
                        // It was not dismissed, so =>
                        setDismiss("no");
                        Snackbar.show({
                            "text": "Oops! You denied permission to send push notifications. If you did it by mistake, contact support@bookaible.ai and we will help you reset it.",
                            duration: 5000,
                            showAction: false,
                        })
                    }
                },
                text: "Bookaible would like to send you push-notifications to keep you informed."
            });

            setSnackShown(true);
        }

        return () => false;
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loader, loading, user]);
    

    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const value = {
        currentUser: {
            user,
            details: userDetails,
            auth
        },
        location: {
            navigate,
            currentPath
        },
        pwa: {
            installApp,
            dismiss: () => setPWADismiss("yes"),
            show: pwaDismiss === "no",
            ...pwa,
        },
        notification: {
            permission
        },
        payload: {
            books,
            completed: books.filter((item) => typeof item?.status !== 'undefined' && item?.trash !== true && item?.flagged !== true && item.status === 2),
            progress: books.filter((item) => typeof item?.status !== 'undefined' && item?.trash !== true && item.status === 1 && item?.flagged !== true),
            total: books.filter( (item) => typeof item?.status !== 'undefined'  && item?.trash !== true && item?.flagged !== true ).length,
            favorite: books.filter((item) => typeof item?.favorite === 'boolean' && item.favorite === true),
        }
    }

    return (
        <DashboardContext.Provider value={value}>
            {(loader || loading) ? <Loader /> : children}
        </DashboardContext.Provider>
    )
}