import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import AuthID from "./pages/AuthID";
import ThankYou from "./pages/ThankYou";

import { Loader, Error } from "./components";
import { useEffect } from "react";

import { useSessionStorage } from '@uidotdev/usehooks'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/auth",
    element: <Auth />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/backdoor/:id",
    element: <AuthID />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/create",
    element: <Dashboard />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/in-progress",
    element: <Dashboard />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/completed",
    element: <Dashboard />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/books/:id",
    element: <Dashboard />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/verify",
    element: <Dashboard />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/my-account",
    element: <Dashboard />,
    errorElement: <Error />,
    loader: Loader,
  },
  {
    path: "/thank-you",
    element: <ThankYou />,
    errorElement: <Error />,
    loader: Loader,
  }
]);


let wakeLock = null;

export default function App() {

  const [ creditDialog, setCreditDialog ] = useSessionStorage("creditDialog", "hidden")

  useEffect(() => {
    (function (d) {
      const appziScript = d.createElement("script");
		appziScript.async = true;
		appziScript.defer = true;
      appziScript.src = `https://w.appzi.io/w.js?token=MhCQk`;
      d.head.appendChild(appziScript);
    })(document);

    if(creditDialog !== "hidden") {
        setCreditDialog("hidden");
    }

    return () => false;
  }, []);


  async function documentVisibilityChange() {
    if (
      wakeLock === null &&
      document.visibilityState === "visible" &&
      "wakeLock" in navigator
    ) {
      // reacquire wake lock
      wakeLock = await navigator.wakeLock.request("screen");
    }

    if (
      document.visibilityState === "hidden" &&
      wakeLock !== null &&
      "wakeLock" in navigator
    ) {
      await wakeLock.release();
      wakeLock = null;
    }
  }

  useEffect( () => {
    documentVisibilityChange()
    
    document.addEventListener("visibilitychange", documentVisibilityChange);

    return () => document.removeEventListener("visibilitychange", documentVisibilityChange);
    
  }, []);

  useEffect( () => {

    const handler = (e) => {
		e.preventDefault();
    };

    document.addEventListener("contextmenu", handler, false);

    return () => {
		document.removeEventListener("contextmenu", handler, true);
    }

  }, [])

  return (
      <RouterProvider router={router} />
  );
}
