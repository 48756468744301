import { useEffect } from "react";
import { useRouteError, useNavigate } from "react-router-dom";

export default function Error() {
  const error = useRouteError();
  const navigate = useNavigate();

  console.error(error);

  
  useEffect( () => {
    let redirect = error.status === 404;

    if(redirect) {
        navigate('/404.html');
        window.location.reload()
    }

    if(error.status !== 404 && import.meta.PROD) {
      window.location.reload();
    }

    return () => {
      redirect = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    error.status !== 404 && !import.meta.PROD && 
    ( <div className="font-poppins">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className="text-sm">
        <b>If you are the developer this for you,</b>
        <i>{error.statusText || error.message}</i>
      </p>
      <p>If you are an user facing this error, please <a href="#" onClick={() => window.location.reload()}>refresh</a> this page. If this error continues, please contact us <a href="mailto:support@bookaible.ai">support@bookaible.ai</a>.</p>
    </div> )
  );
}